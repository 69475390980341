import { createLazyFileRoute } from "@tanstack/react-router";
import { HistoryView } from "../../../../../admin/metal/machine/HistoryView.tsx";

export const Route = createLazyFileRoute("/admin/_simple/metal/machines/history/$location/$uuid")({
    component: Component,
});

function Component() {
    const params = Route.useParams();
    return <HistoryView params={params} />;
}
